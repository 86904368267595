<template>
  <div>
    <field-header :text="question.name" :guide-text="question.guideText"
                  @showCommentBox="o => showComment = o" :has-comment="hasComment" />
    <field-value>
      <score v-model="answer.answer" @input="updated"/>
    </field-value>
    <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>

  </div>
</template>

<script>
  /**
   * This input control can have four states: 'red', 'yellow', 'green' and null.
   * If 'green', the first button is pressed
   * If 'yellow', the second button is pressed
   * If 'red', the third button is pressed.
   * If null, no buttons are pressed.
   * If a button is pressed, and that button is pressed again, it will be unpressed, and
   * the state will revert to null.
   *
   * This is a survey component. For a normal input component without header and all,
   * use the Score component.
   */
  import Score from '@/components/Survey/InputFields/Score';
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';

  export default {
    name: 'score-field',
    mixins: [InputFieldMixin],
    components: {
      Score,
      FieldHeader,
      FieldValue,
      FieldComment,
    },
    watch: {
      'answer.answer': {
        handler() {
          this.updated();
        },
      },
    },
  };
</script>

<style lang="scss">
  .field_value {
    border-bottom: none !important;
  }
</style>

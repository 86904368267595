<template>
    <div>
      <field-header :text="question.name" :guide-text="question.guideText"
                    @showCommentBox="o => showComment = o" :has-comment="hasComment" />
      <field-value>
        <select v-model="currentHourValue" @change="timeUpdated">
          <option v-for="hourValue in hourValues" :key="hourValue" :value="hourValue">{{hourValue}}</option>
        </select>
        <select v-model="currentMinuteValue" @change="timeUpdated">
          <option v-for="minuteValue in minuteValues" :key="minuteValue" :value="minuteValue">{{minuteValue}}</option>
        </select>
      </field-value>
      <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
    </div>
</template>

<script>
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';
  import IsEmpty from '@/components/Mixins/IsEmpty';
  import ComponentEventNames from '@/enums/component-event-names';
  import Answer from '@/types/Answer';

  export default {
    name: 'time-picker',
    components: {
      FieldComment,
      'field-header': FieldHeader,
      'field-value': FieldValue,
    },
    mixins: [InputFieldMixin, IsEmpty],
    data() {
      // hours from 0 to 23
      const hourValues = Array(24).fill(0).map((_, idx) => idx.toString(10).padStart(2, '0'));
      // minutes from 0 to 55, step 5
      const minuteValues = Array(12).fill(0).map((_, idx) => (idx * 5).toString(10).padStart(2, '0'));
      return {
        hourValues,
        minuteValues,
        currentHourValue: '00',
        currentMinuteValue: '00',
      };
    },
    watch: {
      modelValue: {
        handler(newValue) {
          if (this.isEmpty(newValue) || this.isEmpty(newValue.answer)) {
            this.currentHourValue = '00';
            this.currentMinuteValue = '00';
          } else {
            [this.currentHourValue, this.currentMinuteValue] = newValue.answer.split(':');
          }
        },
        immediate: true,
      },
    },
    methods: {
      timeUpdated() {
        console.debug('timeUpdated');
        const answer = new Answer({ answer: `${this.currentHourValue}:${this.currentMinuteValue}` });
        this.$emit(ComponentEventNames.updateModelValue, answer);
      },
    },
  };
</script>

<style scoped lang="scss">
  select {
    width: 50px;
    background-color: #fafafa;
    border: none;
    height: 30px;
  }
</style>

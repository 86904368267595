<template>
  <div class="survey-container">
    <div class="input_list">
      <collapsible-group :initially-collapsed="true">
        <template v-slot:header="slotProps">
          <h1 id="surveyHeadline">
            <icon :name="slotProps.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"/>
            {{ schema.name }}
          </h1>
        </template>
        <template v-slot:content="outerSlotProps">
          <div v-for="(questions, groupName) in questionGroups"
               :key="groupName" class="element materialShadow add_entry">
            <collapsible-group :initially-collapsed="shouldCollapse(questions, model.answers)">
              <template v-slot:header>
                <h2 class="groupHeadline">{{groupName}}</h2>
              </template>
              <template v-slot:content="slotProps">
                <div v-for="question in questions" :key="question.id">
                  <component
                    :is="getComponentName(question.editorType)"
                    :question="question"
                    v-model="model.answers[question.id]"
                  />
                </div>
                <span class="collapse-button">
                  <icon name="fa-chevron-up" @click="slotProps.toggleCollapse()" />
                </span>
              </template>
            </collapsible-group>
          </div>
          <span class="collapse-button outer">
            <icon name="fa-chevron-up" @click="outerSlotProps.collapseAndScroll('#surveyHeadline')" />
          </span>
        </template>
      </collapsible-group>

      <div v-if="isLoadingPrefillData" class="loading">
        <icon name="fa-cog fa-spin"/>
      </div>
      <template v-if="schema.subQuestions.length > 0">
        <div v-for="(subAnswer, key) in model.subAnswers" :key="key">
          <collapsible-group :initially-collapsed="true">
            <template v-slot:header="slotProps">
              <form :id="'updateUnitIdForm'+key" @submit.prevent="updateUnitId">
                <h1 :id="getSubItemId(key)">
                  <icon class="collapse-button"
                        :name="slotProps.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"/>
                  <span style="margin-left:10px"></span>
                  <span v-if="editingUnitId && key===oldUnitId">
                    <input @click.stop="" class="unit-id-input"
                           name="newUnitId" type="text" v-model="newUnitId" ref="newUnitId"><br>
                    <button type="submit"
                            class="lsc-button lsc-rounded green unit-button first-button">
                      {{ x('save') }}
                    </button>
                    <button type="button"
                            class="lsc-button lsc-rounded red unit-button second-button"
                            @click.stop="editingUnitId=false">{{ x('cancel') }}</button>
                  </span>
                  <template v-else>
                    <button v-if="slotProps.collapsed"
                      class="edit-button lsc-button lsc-rounded unit-button"
                            type="button" @click.stop="startEditingUnitId(key)">Omdøb</button>
                    <span>{{ key }}</span>
                  </template>
                </h1>
              </form>
            </template>
            <template v-slot:content="outerSlotProps">
              <delete-button v-if="!editingUnitId" @click="deleteSubItem(key)"
                             class="deleteButton"/>
              <div v-for="(questions, groupName) in subQuestionGroups"
                   :key="groupName" class="element materialShadow add_entry">
                <collapsible-group :initially-collapsed="shouldCollapse(questions, subAnswer)">
                  <template v-slot:header>
                    <h2 class="groupHeadline">{{groupName}}</h2>
                  </template>
                  <template v-slot:content="slotProps">
                    <div v-for="question in questions" :key="question.id">
                      <component
                        :is="getComponentName(question.editorType)"
                        :question="question"
                        v-model="subAnswer[question.id]"
                      />
                    </div>
                    <span class="collapse-button">
                      <icon name="fa-chevron-up" @click="slotProps.toggleCollapse()" />
                    </span>
                  </template>
                </collapsible-group>
              </div>
              <span class="collapse-button outer">
                <icon name="fa-chevron-up" @click="outerSlotProps.collapseAndScroll('#'+getSubItemId(key))" />
              </span>
            </template>
          </collapsible-group>
        </div>
      </template>
      <add-button v-if="addButtonAvailable && !isLoadingPrefillData" @click="addSubItem"/>
    </div>
  </div>
</template>

<script>
  import { groupBy, kebabCase } from 'lodash';
  import ComponentEventNames from '@/enums/component-event-names';
  import NumericField from '@/components/Survey/InputFields/NumericField';
  import OKNotOK from '@/components/Survey/InputFields/OKNotOK';
  import Dropdown from '@/components/Survey/InputFields/Dropdown';
  import DeleteButton from '@/components/Survey/InputFields/DeleteButton';
  import AddButton from '@/components/Survey/InputFields/AddButton';
  import CollapsibleGroup from '@/components/Survey/CollapsibleGroup';
  import TextAreaField from '@/components/Survey/InputFields/TextAreaField';
  import OKNotOKNA from '@/components/Survey/InputFields/OKNotOKNA';
  import EmailField from '@/components/Survey/InputFields/EmailField';
  import Answer from '@/types/Answer';
  import ScoreField from '@/components/Survey/InputFields/ScoreField';
  import TimePicker from '@/components/Survey/InputFields/TimePicker';
  import translate from '../Mixins/Translate';
  import TextField from './InputFields/TextField';

  export default {
    name: 'survey-form',
    components: {
      AddButton,
      DeleteButton,
      dropdown: Dropdown,
      'textarea-field': TextAreaField,
      'text-field': TextField,
      'email-field': EmailField,
      'ok-not-ok': OKNotOK,
      'ok-not-ok-na': OKNotOKNA,
      'numeric-field': NumericField,
      'collapsible-group': CollapsibleGroup,
      ScoreField,
      TimePicker,
    },
    props: ['customerId', 'schema', 'modelValue', 'isLoadingPrefillData'],
    emits: [ComponentEventNames.updateModelValue, 'update-unit-id'],
    mixins: [translate],
    data() {
      return {
        model: this.modelValue,
        editingUnitId: false,
        newUnitId: '',
        oldUnitId: '',
      };
    },
    computed: {
      questionGroups() {
        return groupBy(this.schema.questions, 'groupName');
      },
      subQuestionGroups() {
        return groupBy(this.schema.subQuestions, 'groupName');
      },
      addButtonAvailable() {
        if (this.schema.maxSubs == null) return true;
        return this.subItemCount() < this.schema.maxSubs;
      },
    },
    watch: {
      value: {
        deep: true,
        handler(newValue) {
          this.model = newValue;
        },
      },
      model: {
        deep: true,
        handler(newValue) {
          this.$emit(ComponentEventNames.updateModelValue, newValue);
        },
      },
    },
    methods: {
      /** This is used for collapsing groups to determine if they should start by
       * being collapsed. That is the case if they have all 'ok-not-ok-na' questions,
       * and all answers set to 'na'. */
      shouldCollapse(questions, answers) {
        return questions
          .reduce((accumulator, currentValue) => accumulator
            && currentValue.editorType === 'ok-not-ok-na'
            && answers[currentValue.id].answer === 'na', true);
      },
      /** This is the number of sub-items (i.e. pumps) created in this survey. Note
       * that this is not the same as sub-questions. */
      subItemCount() {
        return Object.keys(this.model.subAnswers).length;
      },
      getSubItemId(key) {
        return `sub-${kebabCase(key)}`;
      },
      getComponentName(editorType) {
        switch (editorType) {
          case 'text':
            return 'text-field';
          case 'email':
            return 'email-field';
          case 'ok-not-ok':
            return 'ok-not-ok';
          case 'ok-not-ok-na':
            return 'ok-not-ok-na';
          case 'number':
            return 'numeric-field';
          case 'dropdown':
            return 'dropdown';
          case 'textarea':
            return 'textarea-field';
          case 'score':
            return 'score-field';
          case 'time':
            return 'time-picker';
          default:
            return 'text-field';
        }
      },

      deleteSubItem(key) {
        delete this.model.subAnswers[key];
      },

      addSubItem() {
        const { name, subItem } = this.createSubItem();
        this.model.subAnswers[name] = subItem;
      },

      createSubItem() {
        const subItem = {};
        this.schema.subQuestions
          .forEach((q) => { subItem[q.id] = new Answer(); });

        const name = this.getNextSubItemName();
        console.log(name);
        return {
          name,
          subItem,
        };
      },

      getNextSubItemName() {
        const names = Object.keys(this.model.subAnswers);
        const prefix = this.$store.state.config.translation.surveySubName;
        if (names.length === 0) {
          return prefix + 1;
        }
        let found = false;
        let index = 1;
        let newName;
        while (!found) {
          newName = prefix + index;
          if (names.indexOf(newName) === -1) {
            found = true;
          } else {
            index += 1;
          }
        }
        if (found) {
          return newName;
        }

        return '';
      },

      startEditingUnitId(key) {
        this.editingUnitId = true;
        this.newUnitId = key;
        this.oldUnitId = key;
      },

      updateUnitId() {
        this.$emit('update-unit-id', { newName: this.newUnitId, oldName: this.oldUnitId });
        this.editingUnitId = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "survey-form";

  .subHeader {
    font-weight: bold;
  }

  .deleteButton {
    float: right;
    margin: -35px 14px 4px 4px;
    padding: 5px 20px 4px 20px;
  }

  .edit-button {
    float: right;
  }

  .loading {
    text-align: center;
    font-size: 24px;
    margin: 24px auto;
  }

  .unit-button {
    font-size: 14px;
    text-align: center;
    width: 100px;
    padding: 5px 20px 4px 20px;
  }

  .first-button {
    margin-left: 28px;
  }

  .second-button {
    margin-left: 10px;
  }

  .unit-id-input {
    font-size: 14px;
    width: calc(100% - 30px);
    height: 25px;
  }

  .collapse-button {
    color: var(--ThemeTakePicH2Color);

    &.outer {
      font-size: 20px;
      margin-left: 10px;
    }
  }
</style>

<template>
  <div>
    <field-header :text="question.name" :guide-text="question.guideText"
                  @showCommentBox="o => showComment = o" :has-comment="hasComment" />
    <div class="field_value">
      <button :class="['ok', { selected: answer.answer === 'ok' }]" @click="clickedOk" tabindex="0">
        <i class="fa fa-smile-o"></i>
      </button>
      <button :class="['not-ok', { selected: answer.answer === 'not ok' }]"
         @click="clickedNotOk" tabindex="0">
        <i class="fa fa-exclamation-circle"></i>
      </button>
      <button :class="['na', { selected: answer.answer === 'na' }]"
         @click="clickedNA" tabindex="0">
        <i class="fa fa-times"></i>
      </button>
      <div class="lsc-clear"></div>
    </div>
    <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
  </div>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';
  import Answer from '@/types/Answer';

  /**
   * This input control can have four states: 'ok', 'not ok', 'na' and null.
   * It is like the 'ok-not-ok', except for an explicit 'no answer' answer.
   * If 'ok', the first button is pressed
   * If 'not ok', the second button is pressed
   * If 'na', the third button is pressed.
   * If null, no buttons are pressed.
   * If a button is pressed, and that button is pressed again, it will be unpressed, and
   * the state will revert to null.
   */
  export default {
    name: 'ok-not-ok-na',
    mixins: [InputFieldMixin],
    components: {
      'field-header': FieldHeader,
      FieldComment,
    },
    data() {
      return {
        answer: this.parseAnswer(this.modelValue.clone()),
      };
    },
    methods: {
      // Only set known values, other values are turned into null
      parseAnswer(clonedAnswer) {
        const answer = clonedAnswer;
        answer.answer = String(answer.answer).toLowerCase().trim();

        switch (answer.answer) {
          case 'ok':
          case 'not ok':
          case 'na':
            return answer;
          default:
            return new Answer({ answer: '', comment: answer.comment });
        }
      },

      clickedOk() {
        // toggle answer value
        if (this.answer.answer === 'ok') {
          this.answer.answer = null;
        } else {
          this.answer.answer = 'ok';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.answer);
      },

      clickedNotOk() {
        // toggle answer value
        if (this.answer.answer === 'not ok') {
          this.answer.answer = null;
        } else {
          this.answer.answer = 'not ok';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.answer);
      },
      clickedNA() {
        if (this.answer.answer === 'na') {
          this.answer.answer = null;
        } else {
          this.answer.answer = 'na';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.answer);
      },
    },
  };
</script>

<style scoped lang="scss">
 .field_value {
    /*border-bottom: 1px solid;*/
    /*border-bottom-color: #cccccc;*/
    margin-bottom: 15px;
  }

  button {
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
    color: #ababab;
    border: 1px dashed #ababab;
    width: calc(33% - 10px);
    padding: 10px;
    text-align: center;
    cursor: pointer;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #fafafa;

    i {
      font-size: 32px;
    }
  }

  button.selected {
    border: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  }

  button.ok.selected {
    background: var(--ThemeSurveyOk3Button);
    color: var(--ThemeSurveyOk3ButtonText);
  }

  button.not-ok.selected {
    background: var(--ThemeSurveyNotOk3Button);
    color: var(--ThemeSurveyNotOk3ButtonText);
  }

  button.na.selected {
    background: var(--ThemeSurveyNA3Button);
    color: var(--ThemeSurveyNA3ButtonText);
  }

  button:last-of-type {
    margin-right: 0 !important;
  }

</style>

<template>
  <div class="field_value">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'field-value',
  };
</script>

<style scoped lang="scss">
 .field_value {
    border-bottom: 1px solid;
    border-bottom-color: #cccccc;
    margin-bottom: 20px;
  }

</style>

<template>
    <div>
      <field-header :text="question.name" :guide-text="question.guideText"
                    @showCommentBox="o => showComment = o" :has-comment="hasComment" />
      <field-value>
        <input type="text" v-model="answer.answer" @input="updated">
      </field-value>
      <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
    </div>
</template>

<script>
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';

  export default {
    name: 'text-field',
    components: {
      FieldComment,
      'field-header': FieldHeader,
      'field-value': FieldValue,
    },
    mixins: [InputFieldMixin],
  };
</script>

<style scoped lang="scss">
  input {
    width: 100%;
    background-color: #fafafa;
    border: none;
    height: 30px;
  }
</style>

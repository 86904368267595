import Answer from '@/types/Answer';
import ComponentEventNames from '@/enums/component-event-names';
import { reactive } from 'vue';

/**
 * This contains shared code for the input fields.
 */
export default {
  props: {
    modelValue: Answer,
    question: Object,
  },
  emits: [ComponentEventNames.updateModelValue],
  data() {
    return {
      answer: this.modelValue.clone(),
      showComment: false,
      // To implement validation for a field type: Override this property with true in the
      // input component and implement a method called validate(), which sets answer.hasError
      // That function will get called after each update to the field contents.
      shouldValidate: false,
    };
  },
  computed: {
    hasComment() {
      return (typeof this.answer.comment === 'string' && this.answer.comment.length > 0);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.answer = reactive(this.modelValue.clone());
      },
    },
  },
  methods: {
    updated() {
      // At this point, this.answer has been updated reactively,
      // either the answer or the comment prop.
      if (this.shouldValidate) {
        this.validate();
      }
      this.$emit(ComponentEventNames.updateModelValue, this.answer);
    },
  },
};

<template>
  <div id="editVisit" class="item popUpScrollList" v-if="!isLoading">
    <image-list ref="cloudinaryUpload"
                v-model="visit.pictures"
                :cover-image="visit.firstPictureName"
                :customer-id="visit.customerId"
                @uploadingCount="uploadingCountChanged"
                @setCoverImage="setCoverImage"
                :edit-mode="editMode"
                :image-view-mode="2"/>

    <div style="display: none">
      <p v-if="visit.campaigns && visit.campaigns.length > 0">
        <label for="categoryId">Category ID:</label>
        <input id="categoryId" name="categoryId" v-model="visit.campaigns[0].id"/>
      </p>
    </div>

    <div class="lsc-clear"></div>
    <dl class="add_entry_list">

      <dt v-if="visit.reactionType==='idea'" class="anim_fadeIn">
        <h2 class="tp_headline">{{ x('title') }}</h2>
      </dt>

      <dd v-if="visit.reactionType==='idea'" class="anim_fadeIn">
        <input title="title" class="title" v-model="visit.title" :placeholder="x('writeATitle')">
      </dd>

      <div class="element materialShadow add_entry">

        <customer-select v-show="visit.reactionType !== 'idea'" :customer="visit.customer[0]"
                         :edit-mode="editMode" @updated="updateCustomer"/>
      </div>
      <survey-form :customer-id="visit.customerId"
                   v-model="visit.survey"
                   :schema="schema" :is-loading-prefill-data="isLoadingPrefillData"
                   @update-unit-id="updateUnitId"/>

      <div v-if="isScoringEnabled" class="element materialShadow add_entry">
        <h2 class="tp_headline">{{ x('visitScoreName') }}</h2>
        <score v-model="visit.score"/>
      </div>

      <dd class="description">
        <div class="element materialShadow add_entry">
          <textarea title="comments" :placeholder="x('commentDots')" name="comment"
                    v-model="visit.comment" maxlength="2000"/>
        </div>
      </dd>
    </dl>

    <div class="element materialShadow add_entry">
      <div class="missingData" v-show="errorMessage !== ''">
        <icon name='fa-exclamation-circle'/>
        <br>{{ errorMessage }}
      </div>

      <button class="lsc-button lsc-rounded-10 red"
              @click.once="cancel">
        <icon name="fa-close"/>
        {{ config.translation['cancel'] }}
      </button>
      <button :disabled="isUploading || isSubmitting" type="submit"
              class="lsc-button lsc-rounded-10 green right width100" id="entry_submit" @click="submit">
        <icon name="fa-upload"/>
        <span v-if="isUploading || isSubmitting" class="button-spinner">
          <icon name="fa-cog fa-spin" />
        </span>
        <template v-else>
          {{ x('save') }}
        </template>
      </button>
    </div>

    <div class="add_entry visit_confirm" :class="{ open: isShowingSuccessUI }">
      <div class="response_container">
        <i class='fa fa-check-circle'></i><br>
        {{ responseText }}
      </div>
    </div>
    <div class="hotfix-air"></div>

  </div>
</template>

<script>
  // Tools
  import moment from 'moment';
  import { cloneDeep, uniqBy } from 'lodash';
  import { mapState, mapGetters } from 'vuex';
  import { httpPut, httpPost, httpGet } from '@/classes/httpHelper';

  // Components
  import ComponentEventNames from '@/enums/component-event-names';
  import Answer from '@/types/Answer';
  import { createNewVisit } from '@/classes/visit-helper';
  import ImageList from '../ImageUpload/ImageList';
  import CustomerSelect from '../EditVisit/CustomerSelect';
  import SurveyForm from './SurveyForm';
  import Score from './InputFields/Score';

  // Business logic
  import translate from '../Mixins/Translate';

  moment.locale('da');

  export default {
    name: 'survey',
    props: {
      loadedVisit: Object,
    },
    emits: [
      ComponentEventNames.didLoad,
      ComponentEventNames.close,
    ],
    components: {
      'image-list': ImageList,
      'customer-select': CustomerSelect,
      'survey-form': SurveyForm,
      score: Score,
    },
    mixins: [translate],

    data() {
      const editMode = this.loadedVisit !== undefined;
      const visit = this.loadedVisit
        ? { ...this.loadedVisit }
        : this.createNewVisitWithSurvey();

      if (editMode) {
        if (visit.products === undefined) {
          visit.products = [];
        }
        if (visit.campaigns === undefined) {
          visit.campaigns = [];
        }
        if (visit.survey === undefined) {
          visit.survey = {
            answers: [],
            subAnswers: [],
          };
        }
      }

      return {
        availableReactions: [],
        buttonCountClass: 'Options_1',
        userIsInternal: this.$store.state.user.userRole.toLowerCase() === 'internal',
        editMode,
        errorMessage: '',
        visit,
        quickCampaigns: [],
        isLoadingCampaigns: true,
        allCampaigns: [],
        isSubmitting: false,
        isUploading: false,
        isLoading: editMode, // need to load survey schema if editing.
        isLoadingPrefillData: true,
        schema: editMode ? {} : this.$store.state.SurveyStore.currentSurvey,
        isShowingSuccessUI: false,
      };
    },

    computed: {
      customer() {
        return this.visit.customer[0] || null;
      },
      isScoringEnabled() {
        return this.schema.visitScoreEnabled;
      },
      hasAnswersWithErrors() {
        const invalidAnswers = Object.values(this.visit.survey.answers)
          .filter((answer) => answer.hasError);
        const invalidSubAnswers = Object.values(this.visit.survey.subAnswers)
          .filter((answer) => answer.hasError);
        return invalidAnswers.length + invalidSubAnswers.length > 0;
      },
      responseText() {
        return this.config.userRole === 'internal'
          ? this.config.obj_texts.FullSentFinishMess
          : this.config.obj_texts.LightSentFinishMess;
      },
      ...mapState(['config', 'user']),
      ...mapState('LocationStore', ['location']),
      ...mapState('SurveyStore', ['currentSurvey']),
      ...mapState('VisitPlanStore', ['preselectedCustomer']),
      ...mapGetters('VisitPlanStore', ['isVisitPlanState']),
    },

    watch: {
      location: {
        handler(newLocation) {
          if (!this.editMode) {
            this.visit.latitude = newLocation.latitude;
            this.visit.longitude = newLocation.longitude;
          }
        },
        deep: true,
      },
      'visit.customerId': async function fn(newValue) {
        if (this.hasValue(newValue)) {
          await this.loadPreFillData();
        }
      },
      'visit.survey': {
        deep: true,
        handler() {
          this.calculateProgress();
        },
      },
    },

    async created() {
      if (this.visit) {
        // Ensure that we cannot affect anything outside this component.
        this.visit = cloneDeep(this.visit);
      }
      this.$store.commit('setCurrentReactionType', 'survey');
    },

    async mounted() {
      if (this.editMode) {
        // load survey data
        const surveyAndResponse = await httpGet(`surveys/byvisit/${this.visit.id}`);
        this.schema = surveyAndResponse.schema;
        this.setExistingResponse(surveyAndResponse.response, this.schema.id);
      }
      if (!this.editMode && this.hasValue(this.visit.customerId)) {
        await this.loadPreFillData();
      }
      await this.$nextTick();
      this.isLoading = false;
      this.isLoadingPrefillData = false;
      console.log('mounted done');
      this.$emit(ComponentEventNames.didLoad);
    },

    beforeUnmount() {
      this.$store.commit('setCurrentReactionType', null);
    },

    methods: {
      calculateProgress() {
        // This might be called before the schema is loaded.
        if (this.schema === undefined || this.schema.questions === undefined) return;

        let questions = 0;
        let answers = 0;
        const questionsToCount = this.schema.questions
          .concat(this.schema.subQuestions)
          .filter((q) => q.includeInProgressCount)
          .map((q) => q.id);
        Object.entries(this.visit.survey.answers).forEach(([q, a]) => {
          if (questionsToCount.indexOf(q) > -1) {
            questions += 1;
            if (!this.isEmpty(a.answer)) {
              answers += 1;
            }
          }
        });
        Object.values(this.visit.survey.subAnswers).forEach((subItem) => {
          Object.entries(subItem).forEach(([q, a]) => {
            if (questionsToCount.indexOf(q) > -1) {
              questions += 1;
              if (!this.isEmpty(a.answer)) {
                answers += 1;
              }
            }
          });
        });

        const progress = Math.round((answers / questions) * 100);
        this.$store.commit('setTopBarSuffix', `(${progress}%)`);
      },

      /**
       * Note: This method is called from the data() function,
       * which is pretty early in the component lifecycle.
       * So the "config" property is not there yet, and we have to use $store.state.config instead.
       * @returns {{userId, userRole, pictures: Array, customer: Array,
       * products: Array, campaigns: Array, reactionType: string}}
       */
      createNewVisitWithSurvey() {
        const currentSurvey = this.$store.state.SurveyStore.currentSurvey;
        const surveyObj = {
          surveyTemplateId: currentSurvey.id,
          answers: {},
          subAnswers: {},
        };
        // Only questions, not subQuestions, are added.
        // SubQuestions are added via loadPreFillData, and multiplied by the number
        // of pre-filled answers present.
        currentSurvey.questions.forEach((q) => {
          surveyObj.answers[q.id] = new Answer({ answer: q.defaultAnswer });
        });
        const user = this.$store.state.user;
        const preselectedCustomer = this.$store.state.VisitPlanStore.preselectedCustomer;
        const visit = createNewVisit(user.id, user.userRole, 'survey', preselectedCustomer);
        return {
          ...visit,
          survey: surveyObj,
        };
      },

      /**
       * Retrieves pre-filled answers for questions and sub-questions.
       * Prefill data for sub-questions determine how many subItems are
       * set to begin with.
       */
      async loadPreFillData() {
        this.isLoadingPrefillData = true;
        await this.$nextTick();
        // eslint-disable-next-line max-len
        const preFillData = await httpGet(`surveys/prefill?customer=${this.visit.customerId}&survey=${this.currentSurvey.id}`);
        this.visit.survey.answers = {};
        this.currentSurvey.questions.forEach((q) => {
          this.visit.survey.answers[q.id] = new Answer({ answer: q.defaultAnswer });
        });
        this.visit.survey.subAnswers = {};
        const questionPreFillData = preFillData.filter((item) => this.currentSurvey.questions
          .find((q) => q.id === item.surveyQuestionId));
        const subquestionPreFillData = preFillData.filter((item) => this.currentSurvey.subQuestions
          .find((q) => q.id === item.surveyQuestionId));

        // Pre-fill (or clear) current answers
        const questionIdsForPrefilledAnswers = Object.keys(this.visit.survey.answers);
        questionIdsForPrefilledAnswers.forEach((key) => {
          const preFillItem = questionPreFillData.find((item) => item.surveyQuestionId === key);
          if (preFillItem) {
            this.visit.survey.answers[key] = new Answer({ answer: preFillItem.answer });
          }
        });

        const subGroups = this.subGroups(preFillData);
        subGroups.forEach((subGroup) => {
          const subItem = {};
          const currentSurvey = this.$store.state.SurveyStore.currentSurvey;
          currentSurvey.subQuestions.forEach((sq) => {
            subItem[sq.id] = new Answer({ answer: sq.defaultAnswer });
          });
          subquestionPreFillData
            .filter((item) => item.unitId === subGroup.unitId)
            .forEach((item) => {
              // TODO: TEST VUE3
              subItem[item.surveyQuestionId] = new Answer({ answer: item.answer });
              // this.$set(subItem, item.surveyQuestionId, new Answer(item.answer)); NOTE NEW CONSTRUCTOR
            });
          // TODO: TEST VUE3
          this.visit.survey.subAnswers[subGroup.unitId] = subItem;
          // this.$set(this.visit.survey.subAnswers, subGroup.unitId, subItem);
        });
        this.isLoadingPrefillData = false;
      },

      setExistingResponse(response, surveyTemplateId) {
        this.visit.survey = {
          surveyTemplateId,
          answers: {},
          subAnswers: {},
        };
        response.answers.forEach((a) => {
          const question = this.schema.questions.find((q) => q.id === a.surveyQuestionId);
          // Check if this is a main question
          if (question !== undefined) {
            // this.$set(this.visit.survey.answers, a.surveyQuestionId,
            // new Answer(a.answer, a.comment));  NOTE NEW CONSTRUCTOR
            // TODO: TEST VUE3
            this.visit.survey.answers[a.surveyQuestionId] = new Answer({ answer: a.answer, comment: a.comment });
          } else {
            const subQuestion = this.schema.subQuestions.find((q) => q.id === a.surveyQuestionId);
            if (subQuestion !== undefined) {
              const unitId = a.unitId;
              if (!(unitId in this.visit.survey.subAnswers)) {
                // Create the container object for the answers for the unit.
                // TODO: TEST VUE3
                // this.$set(this.visit.survey.subAnswers, unitId, new Answer());
                this.visit.survey.subAnswers[unitId] = {};
              }
              // TODO: TEST VUE3
              // this.$set(this.visit.survey.subAnswers[unitId], a.surveyQuestionId,
              // new Answer(a.answer, a.comment)); NOTE NEW CONSTRUCTOR
              this.visit.survey.subAnswers[unitId][a.surveyQuestionId] = new Answer({
                answer: a.answer,
                comment: a.comment,
              });
            }
          }
        });
      },

      subGroups(preFillData) {
        const subGroupData = preFillData
          .filter((item) => this.currentSurvey.subQuestions
            .find((q) => q.id === item.surveyQuestionId));
        return uniqBy(subGroupData, 'unitId');
      },

      updateCustomer(newCustomer) {
        this.visit.customerId = newCustomer.id;
        if (this.visit.customer.length === 0) {
          this.visit.customer.push(newCustomer);
        } else {
          // Ensures vue reactivity
          this.visit.customer.splice(0, 1, newCustomer);
        }
      },

      uploadingCountChanged(newCount) {
        this.isUploading = newCount !== 0;
      },

      setCoverImage(imageName) {
        this.visit.firstPictureName = imageName;
      },

      async cancel() {
        // Roll back image changes. I.e. remove any added images.
        // Removed images are not deleted until commit is called,
        // so we don't need to do anything there.
        if (this.editMode) {
          await this.$refs.cloudinaryUpload.rollbackImageChanges();
        }

        if (this.isVisitPlanState) {
          // then this view was launched by clicking an item in a visit plan
          this.$store.commit('VisitPlanStore/clearPreselectedCustomer');
        }

        // Clear progress text in topbar
        this.$store.commit('setTopBarSuffix', '');
        this.$emit(ComponentEventNames.close);
      },

      validateVisit(visit) {
        if ((this.config.obj_texts.visitScoreMandatory === true && this.visit.reactionType !== 'survey')
          || (this.visit.reactionType === 'survey' && this.schema.visitScoreEnabled)) {
          if (this.isEmpty(visit.score)) {
            return this.x('visitScoreRequired');
          }
        }

        if (visit.pictures.length === 0) {
          // noinspection JSUnresolvedVariable
          return this.x('validationNoImages');
        }

        if (!this.hasValue(this.visit.customerId)) {
          // noinspection JSUnresolvedVariable
          return this.x('validationNoCustomer');
        }

        if (this.hasAnswersWithErrors) {
          return this.x('fieldHasInvalidContent');
        }

        return '';
      },

      hasValue(value) {
        return (value !== undefined && value !== null && value !== '');
      },

      updateUnitId(data) {
        const { newName, oldName } = data;

        // This is an attempt to insert properties in an object in the same order that they
        // previously were, to preserve the subItem list sequence.
        const tempValues = [];
        for (const unitId in this.visit.survey.subAnswers) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.visit.survey.subAnswers.hasOwnProperty(unitId)) {
            tempValues.push({
              unitId: unitId === oldName ? newName : unitId,
              value: this.visit.survey.subAnswers[unitId],
            });
          }
        }
        // TODO: TEST VUE3
        // this.$set(this.visit.survey, 'subAnswers', {});
        this.visit.survey.subAnswers = {};
        for (const item of tempValues) {
          // TODO: TEST VUE3
          // this.$set(this.visit.survey.subAnswers, item.unitId, item.value);
          this.visit.survey.subAnswers[item.unitId] = item.value;
        }
      },

      async submit() {
        if (this.visit.reactionType === 'idea') {
          // Set no customer when an idea is submitted.
          this.visit.customerId = 'NA';
        }
        this.errorMessage = this.validateVisit(this.visit);

        if (this.errorMessage === '') {
          // Make sure we don't get double-clicks
          if (this.isSubmitting) return;
          this.isSubmitting = true;
          if (this.editMode) {
            // Update existing visit
            await this.$refs.cloudinaryUpload.commitImageChanges();
            await this.sendUpdateToServer(this.visit);
          } else {
            // Create new visit
            await this.sendCreateToServer(this.visit);
          }
        } else {
          // Remove error message after 3 seconds
          setTimeout(() => { this.errorMessage = ''; }, 3000);
        }
      },

      async sendUpdateToServer(visit) {
        const data = await httpPut(`visits/${visit.id}`, visit);
        if ('error' in data) {
          console.warn('Error on server: ', data.error.message);
          if (data.error.message in this.config.translation) {
            this.errorMessage = this.config.translation[data.error.message];
          } else {
            this.errorMessage = data.error.message;
          }
          this.isSubmitting = false;
        } else {
          this.visit.pictures = data.pictures;
          this.visit.firstPictureName = data.firstPictureName;
          this.$emit(ComponentEventNames.close);
        }
      },

      async sendCreateToServer(visit) {
        const response = await httpPost('visits', visit);
        if ('error' in response) {
          console.warn('Error on server: ', response.error.message);
          if (response.error.message in this.config.translation) {
            this.errorMessage = this.config.translation[response.error.message];
          } else {
            this.errorMessage = response.error.message;
          }
          this.isSubmitting = false;
        } else {
          this.showSuccessUI(response);
        }
      },

      showSuccessUI(response) {
        if (response.ok) {
          let errorMessageTimeOut = 2000;

          if (this.config.userRole !== 'internal') {
            errorMessageTimeOut = 4000;
          }
          this.isShowingSuccessUI = true;

          setTimeout(() => {
            this.$store.commit('setTopBarSuffix', '');
            this.$store.commit('popPopup');
            setTimeout(() => {
              console.log('Removing Creation Confirmation');
              this.isShowingSuccessUI = false;
            }, 2000);
          }, errorMessageTimeOut);
        }
      },
    },
  };
</script>

<style scoped>
  .missingData {
    display: block;
  }

  .add_entry_list {
    display: block;
  }

  .title {
    width: 100%;
  }

  button#entry_submit:disabled {
    opacity: 0.5;
  }

  .width100 {
    width: 100px;
  }

  .button-spinner {
    display: inline-block;
    font-size: 20px;
    margin-left: 15px;
    position: absolute;
    margin-top: -3px;
  }
</style>

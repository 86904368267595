<template>
  <div>
    <p class="field_header">{{ x('comments') }}:</p>
    <textarea v-model="editedValue"></textarea>
  </div>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'field-comment',
    props: ['modelValue'],
    emits: [ComponentEventNames.updateModelValue],
    mixins: [translate],
    data() {
      return {
        editedValue: this.modelValue,
      };
    },
    watch: {
      modelValue() {
        this.editedValue = this.modelValue;
      },
      editedValue(newValue) {
        this.$emit(ComponentEventNames.updateModelValue, newValue);
      },
    },
  };
</script>

<style scoped>
  textarea {
    width: 90%;
    background-color: #fafafa;
    border: none;
    height: 100px;
  }

  .field_header {
    font-size: 10px;
  }
</style>

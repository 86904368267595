<template>
  <div>
    <field-header :text="question.name" :guide-text="question.guideText"
                  @showCommentBox="o => showComment = o" :has-comment="hasComment"/>
    <field-value>
      <select v-model="answer.answer" @input="updatedValue">
        <option disabled value="">-- {{ x('select').toLowerCase() }} --</option>
        <option v-for="listValue in listValues" :value="listValue.value" :key="listValue.value">
          {{listValue.displayText}}
        </option>
      </select>
    </field-value>
    <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
  </div>
</template>

<script>
  import translation from '@/components/Mixins/Translate';
  import getDropdownData from '@/classes/dropdown-data';
  import ComponentEventNames from '@/enums/component-event-names';
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';

  export default {
    name: 'dropdown',
    mixins: [InputFieldMixin, translation],
    data() {
      return {
        listValues: [],
      };
    },
    components: {
      'field-header': FieldHeader,
      'field-value': FieldValue,
      FieldComment,
    },
    async mounted() {
      this.listValues = await getDropdownData(this.question.listSource,
                                              this.question.listDisplayProperty,
                                              this.question.listValueProperty);
    },
    methods: {
      updatedValue(evt) {
        const { value } = evt.target;
        this.answer.answer = value;
        this.$emit(ComponentEventNames.updateModelValue, this.answer);
      },
    },
  };
</script>

<style scoped lang="scss">
 select {
    width: 100%;
    background-color: #fafafa;
    height: 30px;
    border: none;
  }
</style>

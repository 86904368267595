<template>
    <div>
      <field-header :text="question.name" :guide-text="question.guideText" :show-comment-button="false" />
      <field-value>
        <textarea v-model="answer.answer" @input="updated"/>
      </field-value>
      <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
    </div>
</template>

<script>
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';

  /**
   * Named textarea-field and not just textarea in order to not clash with the html
   * element called textarea.
   */
  export default {
    name: 'textarea-field',
    mixins: [InputFieldMixin],
    components: {
      FieldComment,
      'field-header': FieldHeader,
      'field-value': FieldValue,
    },
  };
</script>

<style scoped lang="scss">
  textarea {
    width: 100%;
    background-color: #fafafa;
    border: none;
    height: 100px;
  }
</style>

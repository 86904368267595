<template>
  <div>
    <a class="guideTextButton" v-if="guideText" @click="showGuideText = !showGuideText">
      <icon name="fa-question-circle"/>
    </a>
    <a v-if="showCommentButton" class="commentButton" :class="{ hasComment: hasComment }"
       @click="showCommentBox = !showCommentBox">
      <icon v-if="hasComment" name="fa-commenting"/>
      <icon v-else name="fa-commenting-o"/>
    </a>
    <p class="field_header">{{text}} </p>
    <p v-if="guideTextVisible" class="guideText">{{ x(guideText) }}</p>
  </div>
</template>

<script>
  import translate from '@/components/Mixins/Translate';

  export default {
    name: 'field-header',
    props: {
      text: String,
      guideText: String,
      showCommentButton: {
        type: Boolean,
        default: true,
      },
      hasComment: Boolean,
    },
    emits: ['showCommentBox'],
    mixins: [translate],
    data() {
      return {
        showGuideText: false,
        showCommentBox: false,
      };
    },
    computed: {
      guideTextVisible() {
        return this.guideText && this.showGuideText;
      },
    },
    watch: {
      showCommentBox(newValue) {
        console.log('showCommentBox changed.');
        this.$emit('showCommentBox', newValue);
      },
    },
  };
</script>

<style scoped lang="scss">
 .field_header {
    /*color: #a0a0a0 !important;//*/
    color: var(--ThemeTakePicH2Color);
    font-size: 14px;
    margin-bottom: 6px;
  }

  .guideTextButton i {
    display: block;
    float: right;
    margin-right: 10px;
    color: var(--ThemeSurveyHelpIcon);
    font-size: 20px;
  }

  .guideText {
    margin-bottom: 6px;
    font-style: italic;
    font-size: 12px;
  }

  .commentButton {
    display: block;
    float: right;
    margin-right: 10px;
    margin-top: -2px;
    color: var(--ThemeSurveyHelpIcon);
    font-size: 20px;
  }

  .commentButton.hasComment {
    color: var(--ThemeTakePicH2Color);
  }
</style>

<template>
  <button class='lsc-button lsc-rounded green'
          @click.stop="$emit('click')">
    <icon name='fa-plus'/>
    Tilføj pumpe
  </button>
</template>

<script>
  import Translate from '@/components/Mixins/Translate';

  export default {
    name: 'add-button',
    emits: ['click'],
    mixins: [Translate],
  };
</script>

<style scoped lang="scss">
  button {
    margin: 20px 10px 10px 10px;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    width: calc(100% - 20px);
    /*width: calc(100% - 20px);*/

    border: 0;
    border-bottom: 4px solid rgba(0,0,0,0.3);
    background: #03a335;
  }

</style>

<template>
  <div>
    <field-header :text="question.name" :guide-text="question.guideText"
                  @showCommentBox="o => showComment = o" :has-comment="hasComment" />
    <field-value>
      <input type="email" v-model="answer.answer" @input="updated" :class="{ 'has-error': answer.hasError }">
    </field-value>
    <field-comment v-if="showComment" v-model="answer.comment" @input="updated"/>
  </div>
</template>

<script>
  import { validateEmailOrBlank } from '@/classes/email-helper';
  import FieldHeader from '@/components/Survey/InputFields/FieldHeader';
  import FieldValue from '@/components/Survey/InputFields/FieldValue';
  import FieldComment from '@/components/Survey/InputFields/FieldComment';
  import InputFieldMixin from '@/components/Survey/InputFields/InputFieldMixin';

  export default {
    name: 'email-field',
    mixins: [InputFieldMixin],
    components: {
      FieldHeader,
      FieldValue,
      FieldComment,
    },
    data() {
      return {
        shouldValidate: true,
      };
    },
    methods: {
      validate() {
        console.log(`Validating ${this.answer.answer} as email.`);
        // TODO: TEST VUE3
        this.answer.hasError = !validateEmailOrBlank(this.answer.answer);
        // this.$set(this.answer, 'hasError', !validateEmailOrBlank(this.answer.answer));
      },
    },
  };
</script>

<style scoped lang="scss">
 input {
    width: 100%;
    background-color: #fafafa;
    border: none;
    height: 30px;
  }

  .has-error {
    border: 1px solid red;
  }
</style>

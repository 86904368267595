<template>
  <div>
    <div class="field_value">
      <button :class="['green', { selected: editedValue === 'green' }]"
              @click="clickedGreen" tabindex="0">
        <icon name="fa-smile-o" />
      </button>
      <button :class="['yellow', { selected: editedValue === 'yellow' }]"
              @click="clickedYellow" tabindex="0">
        <icon name="fa-meh-o" />
      </button>
      <button :class="['red', { selected: editedValue === 'red' }]"
              @click="clickedRed" tabindex="0">
        <icon name="fa-warning" />
      </button>
      <div class="lsc-clear"></div>
    </div>
  </div>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';

  /**
   * This input control can have four states: 'red', 'yellow', 'green' and null.
   * If 'green', the first button is pressed
   * If 'yellow', the second button is pressed
   * If 'red', the third button is pressed.
   * If null, no buttons are pressed.
   * If a button is pressed, and that button is pressed again, it will be unpressed, and
   * the state will revert to null.
   *
   * Note that this component is not a survey component, but a normal input field, i.e.
   * it does not contain a comment field or Answer data type.
   */
  export default {
    name: 'score',
    props: ['modelValue'],
    emits: [ComponentEventNames.updateModelValue],
    data() {
      return {
        editedValue: this.parseInputValue(this.modelValue),
      };
    },
    watch: {
      modelValue(newValue) {
        this.editedValue = newValue;
      },
    },
    methods: {
      // Only set known values, other values are turned into null
      parseInputValue(inputValue) {
        const parsedValue = String(inputValue).toLowerCase().trim();

        switch (parsedValue) {
          case 'green':
          case 'yellow':
          case 'red':
            return parsedValue;
          default:
            return null;
        }
      },
      clickedGreen() {
        if (this.editedValue === 'green') {
          this.editedValue = null;
        } else {
          this.editedValue = 'green';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.editedValue);
      },
      clickedYellow() {
        if (this.editedValue === 'yellow') {
          this.editedValue = null;
        } else {
          this.editedValue = 'yellow';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.editedValue);
      },
      clickedRed() {
        if (this.editedValue === 'red') {
          this.editedValue = null;
        } else {
          this.editedValue = 'red';
        }
        this.$emit(ComponentEventNames.updateModelValue, this.editedValue);
      },
    },
  };
</script>

<style scoped lang="scss">
 .field_value {
    /*border-bottom: 1px solid;*/
    /*border-bottom-color: #cccccc;*/
    margin-bottom: 15px;
  }

  button {
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
    color: #ababab;
    border: 1px dashed #ababab;
    width: calc(33% - 10px);
    padding: 10px;
    text-align: center;
    cursor: pointer;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #fafafa;

    i {
      font-size: 32px;
    }
  }

  button.selected {
    border: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  }

  button.green.selected {
    background: var(--ThemeVisitScoreGreen);
    color: var(--ThemeVisitScoreGreenText);
  }

  button.yellow.selected {
    background: var(--ThemeVisitScoreYellow);
    color: var(--ThemeVisitScoreYellowText);
  }

  button.red.selected {
    background: var(--ThemeVisitScoreRed);
    color: var(--ThemeVisitScoreRedText);
  }

  button:last-of-type {
    margin-right: 0 !important;
  }

</style>
